import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from "./Views/Home";
import Cv from "./Views/Cv";
import Kontakt from "./Views/Kontakt";
import Kunst from "./Views/Kunst";
import InnleggOverview from "./Views/InnleggOverview";
import Innlegg from "./Views/Innlegg";
import { ViewProvider } from "./Context/ViewContext";


const BrowserRouter = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    children: [
      {
        path: "/cv",
        element: <Cv />,
      },
      {
        path: "/kontakt",
        element: <Kontakt />,
      },
      {
        path: "/kunst",
        element: <Kunst />,
        children: [
          {
            path: "utstilling",
            element: <InnleggOverview category="utstilling" />,
            children: [
              {
                path: ":id",
                element: <Innlegg />
              }
            ]
          },
          {
            path: "utsmykking",
            element: <InnleggOverview category="utsmykking" />,
            children: [
              {
                path: ":id",
                element: <Innlegg />
              }
            ]
          },
          {
            path: "verk",
            element: <InnleggOverview category="verk" />,
            children: [
              {
                path: ":id",
                element: <Innlegg />
              }
            ]
          },
          {
            path: "maritimt",
            element: <InnleggOverview category="maritimt" />,
            children: [
              {
                path: ":id",
                element: <Innlegg />
              }
            ]
          },
        ]
      },
    ],
  },
]);

function App() {

  return (
    <ViewProvider>
      <div className="App">
        
        <RouterProvider router={BrowserRouter} />
      </div>
    </ViewProvider>
  );
}

export default App;
