import NewsCards from "../Components/NewsCards";
import Navigation from "../Components/Navigation";
import { Outlet, useLocation } from 'react-router-dom';
import { getHovedsideInnhold } from '../services/sanity';
import { useEffect, useState } from 'react';
import IntroText from "../Components/IntroText.jsx";
import Footer from "../Components/Footer";
import styled from "styled-components";
import { useViewContext } from '../Context/ViewContext.jsx';
import ArtworkCards from "../Components/ArtworkCards";
import { useRef } from 'react';
import fallbackImage from '../Assets/fallback.jpeg'
import videoSourceWebm from '../Assets/video.webm'
import videoSourceMp4 from '../Assets/video.mp4'
import NewsEntry from "../Components/NewsEntry.jsx";
import AnimatedSection from "../Components/Elements/AnimatedSection";

const Content = styled.div`
    min-height: 100vh;
    width: 100%;
    background-color: #111b29ed;
    z-index: 1;
`
const Wrapper = styled.div`
`;

const Entries = styled.div`
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px 20px 40px 20px;

    @media (max-width: 850px) {
        flex-direction: column;
        align-items: center;
    }
`;

const VideoBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; 
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover; 
  background-color: black;
`;

const FallbackImage = styled.img`
  display: none; // hide by default
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: auto;
  background-color: black;
  object-fit: cover;
  z-index: -1; // Ensure the fallback image is below all other content
`;

const NavBackground = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    z-index: 2;
    background-color: #111b29ed;
`;

const Home = () => {
    const { language } = useViewContext();
    const location = useLocation();
    const isOnRoot = location.pathname === '/';
    const fallbackImageRef = useRef(null);

    const [innhold, setInnhold] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let data = await getHovedsideInnhold();
                if (data && data[1]) {
                    setInnhold(data[1]);
                } else {
                    console.error('Data is not in expected format.');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const videoError = () => {
        console.error('Error loading intro video.');
        if (fallbackImageRef.current) {
            fallbackImageRef.current.style.display = 'block';
        }
    }

    return (
        <>
            {isOnRoot && <IntroText />}
            <NavBackground />
            <Navigation />
            <VideoBackground>
                <FallbackImage ref={fallbackImageRef} src={fallbackImage} alt="kunstverk" />
                <StyledVideo autoPlay muted loop playsInline onError={videoError} >
                    <source src={videoSourceMp4} type="video/mp4" />
                    <source src={videoSourceWebm} type="video/webm" />
                    <img src={fallbackImage} alt="kunstverk" />
                </StyledVideo>
            </VideoBackground>
            <Wrapper>
                <Content >
                    {isOnRoot && innhold ? (
                        <>
                            <AnimatedSection>
                                <NewsEntry />

                                <Entries >
                                    <ArtworkCards />
                                    <NewsCards />
                                </Entries>
                            </AnimatedSection>
                        </>
                    ) : (
                        <Outlet />
                    )}

                </Content>
                {innhold && <Footer innhold={innhold} />}
            </Wrapper>
        </>
    );
}

export default Home;
