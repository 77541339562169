import { useEffect, useState } from 'react';
import { getcvSeksjon, getLenker } from '../services/sanity.js';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { useViewContext } from '../Context/ViewContext.jsx';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import Biography from '../Components/Biography';
import CenteredHeader from '../Components/Elements/CenteredHeader.jsx';
import { decodeHtmlEntities, capitalizeFirstLetter } from '../services/helpers'
import BackButtonHeader from '../Components/Elements/BackButtonHeader.jsx';

const Wrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px 20px 60px 20px;

    @media (max-width: 840px) {
        padding: 20px 20px 60px 20px;
    }
`

const TableWrapper = styled(TableContainer)`
    background-color: white; 
`
const Year = styled(TableCell)`
    white-space: nowrap;
`
const LinkCell = styled(TableCell)`
    a {
        text-decoration: none;
        color: #90CAF9;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const Cv = () => {
    const { language } = useViewContext();
    const [cvInnhold, setCvInnhold] = useState(null);
    const [Lenker, setLenker] = useState(null);

    const createData = (beskrivelse, aarstall) => {
        return { beskrivelse, aarstall };
    }

    const createLink = (tittel, url, aarstall) => {
        return { tittel, url, aarstall };
    }

    useEffect(() => {
        setCvInnhold(null);
        async function fetchData() {
            try {
                const data = await getcvSeksjon();

                data.forEach((seksjon) => {
                    const rows = [];

                    if (seksjon.listItems && Array.isArray(seksjon.listItems)) {
                        seksjon.listItems.forEach((element) => {
                            const text = language === "NO" ? element.noText : element.enText;
                            rows.push(createData(decodeHtmlEntities(text), element.aarstall));
                        });
                    }

                    const title = language === "NO" ? seksjon.noTitle : seksjon.enTitle;

                    const innholdEntry = { [title]: rows };
                    setCvInnhold((prevInnhold) => ({ ...prevInnhold, ...innholdEntry }));
                });
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        }
        fetchData();
    }, [language]);

    useEffect(() => {
        setLenker(null);
        async function fetchData() {
            try {
                const rows = [];
                const data = await getLenker();
                data.forEach((lenke) => {
                    const text = language === "NO" ? lenke.noTittel : lenke.enTittel;
                    rows.push(createLink(decodeHtmlEntities(text), lenke.url, lenke.aarstall));
                });

                const title = language === "NO" ? "Lenker" : "Links";
                const innholdEntry = { [title]: rows };
                setLenker((prevLenker) => ({ ...prevLenker, ...innholdEntry }));
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        }
        fetchData();
    }, [language]);
    return (
        <Wrapper>
            <BackButtonHeader>{language === "NO" ? "CV" : "Resume"}</BackButtonHeader>
            <Biography />
            {cvInnhold && (
                Object.keys(cvInnhold).map((name, index) => (
                    <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>{capitalizeFirstLetter(name)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableWrapper component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{language === "NO" ? "Beskrivelse" : "Description"}</TableCell>
                                            <TableCell align="right">{language === "NO" ? "Årstall" : "Year"} </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {cvInnhold[name].map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.beskrivelse}
                                                </TableCell>
                                                <Year align="right">{row.aarstall}</Year>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableWrapper>
                        </AccordionDetails>
                    </Accordion>
                ))
            )}
            {Lenker && (
                Object.keys(Lenker).map((name, index) => (
                    <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography variant='body1' sx={{ fontWeight: 'bold' }} >{name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableWrapper component={Paper}>
                                <Table aria-label="links table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{language === "NO" ? "Beskrivelse" : "Description"}</TableCell>
                                            <TableCell align="right">{language === "NO" ? "Årstall" : "Year"} </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Lenker[name].map((link, linkIndex) => (
                                            <TableRow
                                                key={linkIndex}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <LinkCell component="th" scope="row">
                                                    <a href={link.url} target="_blank" rel="noopener noreferrer">
                                                        {link.tittel}
                                                    </a>
                                                </LinkCell>
                                                <Year align="right">{link.aarstall}</Year>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableWrapper>
                        </AccordionDetails>
                    </Accordion >
                ))
            )}
        </Wrapper>
    );
}

export default Cv;