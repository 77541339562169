import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './global.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider, CssBaseline } from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#111b29', // main dark background color
      paper: '#1a2433',   // slightly lighter for elements like cards, dialogs
    },
    text: {
      primary: '#ffffff', // white text for high contrast
      secondary: '#b0bec5' // grey text for less emphasis
    },
    primary: {
      main: '#90CAF9', // light blue for primary buttons and icons
    },
    secondary: {
      main: '#90CAF9', // soft pink for secondary elements
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline /> {/* Normalizes the styles and applies background */}
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
