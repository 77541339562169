import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { generateImageUrl } from '../services/sanity.js'

const CardComponent = ({ title, text, imageUrl, onClick, cardWidth, cardHeight, year, color }) => {

    return (
        <Card variant="outlined"  sx={{ width: cardWidth}}>
            <CardActionArea onClick={onClick}>
                <CardMedia
                    component="img"
                    height={cardHeight}
                    image={generateImageUrl(imageUrl, cardWidth, cardHeight)}
                    alt="green iguana"
                />
                <CardContent>
                    {title && (
                        <Typography gutterBottom variant="h6" component="div">
                            {title}
                        </Typography>
                    )}
                    {text && (
                        <Typography variant="body1" sx={{ fontWeight: year ? 'bold' : 'normal', opacity: 0.8 }}>
                            {year ? `${text} (${year})` : text}
                        </Typography>
                    )}
                </CardContent>
            </CardActionArea>
        </Card >
    );
}

export default CardComponent;