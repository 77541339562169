import { useNavigate, useLocation } from "react-router-dom";
import { getLatestInnlegg } from "../services/sanity.js";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useViewContext } from "../Context/ViewContext.jsx";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import CardActionArea from '@mui/material/CardActionArea';
import { generateImageUrl } from '../services/sanity'


const Wrapper = styled.div`
border-left: 1px solid #444f5e;
padding-left: 20px;

@media (max-width: 850px) {
    border-left: none;
    width: 100%;
    padding-left: 0;
    padding-top: 20px;
}
`

const CardContainer = styled.div`
    margin: 0px 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 20px;
    flex-wrap: wrap;
`

const StyledCard = styled(Card)`
    span {
        overflow: hidden;
    }
`



const NewsCards = () => {
    const { language } = useViewContext();
    const [nyesteInnlegg, setnyesteInnlegg] = useState([]);
    const [cardWidth, setCardWidth] = useState(() => {
        if (window.innerWidth < 550) {
            return Math.round(window.innerWidth);
        } else if (window.innerWidth < 830) {
            return Math.round(window.innerWidth / 2.25);
        } else {
            return 250;
        }
    });
    const cardHeight = Math.round((9 / 16) * cardWidth);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        async function fetchData() {
            const data = await getLatestInnlegg(5);
            setnyesteInnlegg(data);
        }
        fetchData();

    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 550) {
                setCardWidth(Math.round(window.innerWidth));
            } else if (window.innerWidth < 830) {
                setCardWidth(Math.round(window.innerWidth / 2.25));
            } else {
                setCardWidth(250);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const isOnRoot = location.pathname === '/';

    if (isOnRoot) return (
        <Wrapper>
            <div>
                <h2>{language === "NO" ? "Siste innlegg" : "Latest posts"}</h2>
                <CardContainer>
                    {Object.entries(nyesteInnlegg).map(([key, innlegg]) => (
                        <StyledCard variant="outlined" key={innlegg._id}>
                            <CardActionArea key={innlegg._id} onClick={() => { navigate(`kunst/${innlegg.kategori}/${innlegg._id}`) }}>
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label="recipe" src={generateImageUrl(innlegg.hovedBilde.asset._ref, 60, 60)} />
                                    }
                                    title={language === "NO" ? innlegg.noTittel : innlegg.enTittel}
                                    subheader={innlegg.aarstall + " - " + innlegg.kategori}
                                />
                            </CardActionArea>
                        </StyledCard>

                    ))}
                </CardContainer>
            </div>
        </Wrapper>
    );

    return null;
}

export default NewsCards;