import ContactForm from "../Components/ContactForm";
import ContactCard from "../Components/ContactCard";
import styled from "styled-components";
import BackButtonHeader from "../Components/Elements/BackButtonHeader";
import { useViewContext } from "../Context/ViewContext";

const Wrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px 20px 60px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

    @media (max-width: 840px) {
        padding: 20px 20px 60px 20px;
    }
`

const CardWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`
const Kontakt = () => {
    const { language } = useViewContext();
    return (
        <Wrapper>
            <BackButtonHeader>{language === 'no' ? 'Kontakt' : 'Contact'}</BackButtonHeader>
            <CardWrapper>
                <ContactCard />
            </CardWrapper>
            <ContactForm />
        </Wrapper>
    );
}
export default Kontakt;