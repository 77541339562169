import styled from 'styled-components';
import CardComponent from '../Components/Card';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getCategories } from '../services/sanity.js';
import CircularProgress from '@mui/material/CircularProgress';
import { useViewContext } from '../Context/ViewContext.jsx';
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-bottom: 60px;

    h2 {
        align-self: flex-start;
    }
`

const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;

    @media (max-width: 860px) {
        justify-content: center;
    }
`

const ArtworkCards = () => {
    const { language } = useViewContext();
    const [kategorier, setKategorier] = useState(null);
    const [cardWidth, setCardWidth] = useState(window.innerWidth < 700 ? window.innerWidth : 270);
    const cardHeight = Math.round((9 / 16) * cardWidth);

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchCategories() {
            const data = await getCategories();
            setKategorier(data[0]);
        }
        fetchCategories();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1000) {
                setCardWidth(Math.round(420))
            }
            if (window.innerWidth < 850) {
                setCardWidth(Math.round(window.innerWidth - 60));
            } 
            if (window.innerWidth >= 1000) {
                setCardWidth(270);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (!kategorier) return <Wrapper> <CircularProgress color="inherit" /> </Wrapper>;

    return (
        <Wrapper>
            <h2>{language === "NO" ? "Kategorier" : "Categories"}</h2>
            <CardContainer>
                <CardComponent
                    cardWidth={cardWidth}
                    cardHeight={cardHeight}
                    title={language === "NO" ? "Verk" : "Artwork"}
                    text={language === "NO" ? kategorier.verk_noBeskrivelse : kategorier.verk_enBeskrivelse}
                    imageUrl={kategorier.verk_bilde.asset._ref}
                    onClick={() => { navigate("kunst/verk") }}
                />
                <CardComponent
                    cardWidth={cardWidth}
                    cardHeight={cardHeight}
                    title={language === "NO" ? "Utsmykking" : "Public work"}
                    text={language === "NO" ? kategorier.utsmykking_noBeskrivelse : kategorier.utsmykking_enBeskrivelse}
                    imageUrl={kategorier.utsmykking_bilde.asset._ref}
                    onClick={() => { navigate("kunst/utsmykking") }}

                />
                <CardComponent
                    cardWidth={cardWidth}
                    cardHeight={cardHeight}
                    title={language === "NO" ? "Utstilling" : "Exhibition"}
                    text={language === "NO" ? kategorier.utstilling_noBeskrivelse : kategorier.utstilling_enBeskrivelse}
                    imageUrl={kategorier.utstilling_bilde.asset._ref}
                    onClick={() => { navigate("kunst/utstilling") }}

                />
                <CardComponent
                    cardWidth={cardWidth}
                    cardHeight={cardHeight}
                    title={language === "NO" ? "Maritimt" : "Maritime"}
                    text={language === "NO" ? kategorier.maritimt_noBeskrivelse : kategorier.maritimt_enBeskrivelse}
                    imageUrl={kategorier.maritimt_bilde.asset._ref}
                    onClick={() => { navigate("kunst/maritimt") }}
                />
            </CardContainer>
        </Wrapper>
    );
}

export default ArtworkCards;
