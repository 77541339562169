// ContactForm.js
import { useRef, useState } from 'react';
import { Button, TextField, Box, Snackbar, Alert } from '@mui/material';
import styled from 'styled-components';
import { useViewContext } from '../Context/ViewContext.jsx';
import emailjs from 'emailjs-com';

const StyledForm = styled.form`
    background-color: #1a2433;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
    min-width: 300px;
`;

const StyledTextField = styled(TextField)`
`;

function ContactForm() {
    const formRef = useRef(null);
    const { language } = useViewContext();
    const [values, setValues] = useState({
        full_name: '',
        email: '',
        phone_number: '',
        message: '',
    });

    const [sending, setSending] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues((prevValues) => ({ ...prevValues, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setSending(true);
        setSnackbarMessage('Sender epost...');
        setSnackbarSeverity('info');
        setSnackbarOpen(true);

        //emailjs.sendForm('service_fd3bcqo', 'template_nk7331b', formRef.current, 'Zu7e25j6Vy3b0nIvT') // my email
        emailjs.sendForm('service_stqo7nb', 'template_mlozciq', formRef.current, 'zevpQA3Oiq0plEC4v') // johns email
            .then((result) => {
                setSnackbarMessage('E-post ble sendt!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            }, (error) => {
                setSnackbarMessage('Noe gikk galt. Vennligst prøv igjen, eller skriv e-post til min email adresse i stedet.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            })
            .finally(() => {
                setSending(false);
            });

        setValues({
            full_name: '',
            email: '',
            phone_number: '',
            message: '',
        });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <StyledForm ref={formRef} onSubmit={handleSubmit}>
                <StyledTextField
                    name="full_name"
                    label={language === "NO" ? "Fullt navn" : "Full name"}
                    value={values.full_name}
                    onChange={handleChange}
                    required
                />
                <StyledTextField
                    name="email"
                    label={language === "NO" ? "E-post" : "Email"}
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    required
                />
                <StyledTextField
                    variant="outlined"
                    name="phone_number"
                    label={language === "NO" ? "Telefonnummer" : "Phone number"}
                    value={values.phone_number}
                    onChange={handleChange}
                />
                <StyledTextField
                    name="message"
                    label={language === "NO" ? "Melding" : "Message"}
                    multiline
                    rows={15}
                    value={values.message}
                    onChange={handleChange}
                    required
                />
                <Box sx={{ display: 'flex', justifyContent: "end" }} mt={1}>
                    <Button type="submit" disabled={sending} variant='contained' color='primary'>
                        send
                    </Button>
                </Box>
            </StyledForm>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default ContactForm;
