import React, { useEffect, useState } from 'react';
import { getNews, getInnleggById, generateImageUrl } from '../services/sanity.js';
import { useViewContext } from '../Context/ViewContext.jsx';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  @media (max-width: 840px) {
    padding-bottom: 60px;
  }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

`;

const Controls = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    
`;

const PublishDate = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;
const HorizontalCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0 auto;
  background-color: #1A2433;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid #363F4C;

  h3 {
    margin: 0;
  }

  img {
    width: 100%;
    max-width: 300px;
    max-height: 300px;
    object-fit: cover;
    border-radius: 5px;
  }

  div {
    flex: 1;
  }

  @media (max-width: 700px) {
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

const NewsEntry = () => {
    const { language } = useViewContext();
    const navigate = useNavigate();

    const [news, setNews] = useState(null);
    const [relatedArtworkPath, setRelatedArtworkPath] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getNews();
                setNews(data[0]);
                // Fetch related artwork if available
                const relatedEntryId = data[0]?.relatertInnlegg?._ref;

                if (relatedEntryId) {
                    const relatedEntry = await getInnleggById(relatedEntryId);
                    const relatedCategory = relatedEntry?.kategori;
                    const path = `/kunst/${relatedCategory}/${relatedEntryId}`;
                    setRelatedArtworkPath(path);
                }
            } catch (error) {
                console.error('Error fetching news:', error);
            }
        };

        fetchData();
    }, []);

    // Derived variables for readability
    const title = news
        ? language === 'NO'
            ? news.noTittel
            : news.enTittel
        : '';
    const description = news
        ? language === 'NO'
            ? news.noBeskrivelse
            : news.enBeskrivelse
        : '';
    const imageUrl = news?.hovedBilde?.asset?._ref
        ? generateImageUrl(news.hovedBilde.asset._ref)
        : '';

    const publishedDate = news?._updatedAt ? new Date(news._updatedAt).toLocaleDateString() : '';


    return (
        <Wrapper>
            {news && (
                <>
                    <h2>{language === 'NO' ? 'Nyheter' : 'News'}</h2>
                    <HorizontalCard>
                        {imageUrl && <img src={imageUrl} alt="news" />}
                        <TextWrapper>
                            <div>
                                <h3>{title}</h3>
                                <p>{description}</p>
                            </div>
                            <Controls>
                                <PublishDate>
                                    <EventIcon fontSize="small" color="primary" />
                                    <Typography variant="body2">{publishedDate}</Typography>
                                </PublishDate>
                                {relatedArtworkPath
                                    ? <Button onClick={() => navigate(relatedArtworkPath)}>Les mer</Button>
                                    : null}
                            </Controls>
                        </TextWrapper>
                    </HorizontalCard>
                </>
            )}
        </Wrapper>
    );
};

export default NewsEntry;
