import styled from "styled-components";
import { useEffect, useState } from "react";
import { generateImageUrl } from '../services/sanity.js';
import { Typography, CardActions } from "@mui/material";
import { useViewContext } from "../Context/ViewContext.jsx";
import { Card, CardHeader, CardContent, Avatar } from "@mui/material";
import { getHovedsideInnhold } from '../services/sanity';

const Wrapper = styled.div`
    padding-bottom: 20px;
    position: relative;
`

const Biography = ({ }) => {
    const { language } = useViewContext();

    const [innhold, setInnhold] = useState(null);
    const [lines, setLines] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let data = await getHovedsideInnhold();
                if (data && data[1]) {
                    setInnhold(data[1]);
                    const localisedBio = language === "NO" ? data[1].noBio : data[1].enBio;

                    const lines = localisedBio.split('\n').map((line, index) => (
                        <span key={index}>
                            {line}
                            <br />
                        </span>
                    ));

                    setLines(lines);
                } else {
                    console.error('Data is not in expected format.');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [language]);



    return (
        innhold && (
            <Wrapper>
                <Card>
                    <CardHeader
                        avatar={
                            <Avatar alt="portrett av John" src={generateImageUrl(innhold.portrett.asset._ref, 60, 60)} />
                        }
                        title="John Audun Hauge"
                        subheader={language === "NO" ? "Skulptør" : "sculptor"}
                    />
                    <CardContent>
                        <Typography>{lines}</Typography>
                    </CardContent>
                    <CardActions>
                    </CardActions>
                </Card>
            </Wrapper>

        )
    )
}


export default Biography;