import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { getInnlegg } from '../services/sanity.js';
import CardComponent from '../Components/Card.jsx';
import { Grid } from '@mui/material';
import BackButtonHeader from '../Components/Elements/BackButtonHeader.jsx';
import { useViewContext } from '../Context/ViewContext.jsx';
import { capitalizeFirstLetter } from '../services/helpers';
import AnimatedSection from '../Components/Elements/AnimatedSection.jsx';

const InnleggOverview = ({ category }) => {
    const { language } = useViewContext();
    const location = useLocation();
    const navigate = useNavigate();
    const overViewIsOnRoot = location.pathname === `/kunst/${category}`;

    const [innlegg, setInnlegg] = useState([]);


useEffect(() => {
    async function fetchData() {
        const data = await getInnlegg(category);
        if (data.length > 0) {
            setInnlegg(data);
        }
    }
    fetchData();
}, [category]);

return (
    <>
        {overViewIsOnRoot && (
            <AnimatedSection>
                <BackButtonHeader>{category}</BackButtonHeader>
                <Grid container spacing={2} justifyContent="flex-start">
                    {Object.entries(innlegg).map(([key, innlegg]) => (
                        <Grid item xs={12} sm={12} md={6} lg={6} key={innlegg._id}>
                            <CardComponent
                                cardHeight={250}
                                text={language === "NO" ? capitalizeFirstLetter(innlegg.noTittel) : capitalizeFirstLetter(innlegg.enTittel)}
                                imageUrl={innlegg.hovedBilde.asset._ref}
                                onClick={() => { navigate(`${innlegg._id}`) }}
                                year={innlegg.aarstall}
                            />
                        </Grid>
                    ))}
                </Grid>
            </AnimatedSection>
        )}
        <Outlet />
    </>
);
}

export default InnleggOverview;
