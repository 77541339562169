import * as React from 'react';
import { styled } from 'styled-components';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useEffect, useState } from 'react';
import { getHovedsideInnhold, generateImageUrl } from '../services/sanity'
import { Link } from 'react-router-dom';
import { useViewContext } from '../Context/ViewContext';
import Typography from '@mui/material/Typography';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';

const StyledLink = styled(Link)`
    padding-bottom: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: 10px;
    text-decoration: none!important;
    color: inherit;
    gap: 8px;
    &:hover {
        text-decoration: underline!important;
    }
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 0 7px 10px; 
`;

const ContactCard = () => {
    const { language } = useViewContext();
    const [innhold, setInnhold] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let data = await getHovedsideInnhold();
                if (data && data[1]) {

                    setInnhold(data[1]);
                } else {
                    console.error('Data is not in expected format.');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return innhold ? (
        <Card variant="outlined" sx={{ width: "100%" }}>
            <CardHeader
                avatar={
                    <Avatar alt="portrett av John" src={generateImageUrl(innhold && innhold.portrett.asset._ref, 60, 60)} />
                }
                title="John Audun Hauge"
                subheader={language === "NO" ? "Skulptør" : "Sculptor"}
            />
            <CardContent>
                <>
                    <br />
                    <StyledLink href={`mailto:${innhold.email}`}>
                        <EmailOutlinedIcon fontSize="small" />
                        <Typography variant="body2">{innhold.email}</Typography>
                    </StyledLink>
                    <FlexContainer>
                        <PlaceOutlinedIcon fontSize="small" />
                        <Typography variant="body2">{innhold.adress}</Typography>
                    </FlexContainer>
                </>
            </CardContent>
        </Card>
    ) : (
        <div>loading...</div>
    );
}

export default ContactCard;