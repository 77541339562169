import { useParams } from 'react-router-dom';
import { getInnleggById, generateImageUrl } from '../services/sanity.js';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import BackButtonHeader from '../Components/Elements/BackButtonHeader.jsx';
import AnimatedSection from '../Components/Elements/AnimatedSection.jsx';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useViewContext } from '../Context/ViewContext.jsx';

const Wrapper = styled.div`
    width: 100%;
    margin-bottom: 120px;
`

const Description = styled(Typography)`
    padding: 20px 0;
`

const ImageContainer = styled.div`
    display: flex;
    justify-content: center; 
    align-items: center;
    img {
        max-height: 60dvh;
        max-width: 100%; 
        object-fit: contain;
        width: auto;
        height: auto; 
    }
`;
const Innlegg = () => {
    const { id } = useParams();
    const [innlegg, setInnlegg] = useState(null);
    const [fetchedImages, setFetchedImages] = useState([]);
    const { language } = useViewContext();


    useEffect(() => {
        async function fetchData() {
            const data = await getInnleggById(id);
            setInnlegg(data);
            setFetchedImages([{
                thumbnail: generateImageUrl(data.hovedBilde.asset._ref, 90, 55),
                original: generateImageUrl(data.hovedBilde.asset._ref),
                caption: data.hovedBilde.caption
            }]);

            if (!data.andreBilder) return;
            data.andreBilder.forEach((bilde) => {
                const imageDetails = {
                    thumbnail: generateImageUrl(bilde.asset._ref, 90, 55),
                    original: generateImageUrl(bilde.asset._ref),
                    caption: bilde.caption
                };
                setFetchedImages((prevImages) => [...prevImages, imageDetails]);
            });
        }
        fetchData();
    }, [id]);

    return (
        <Wrapper>
            <AnimatedSection>
                <BackButtonHeader>
                    {innlegg && (
                        (language === "NO" ? innlegg.noTittel : innlegg.enTittel) ||
                        innlegg.noTittel ||
                        innlegg.enTittel ||
                        "No Title Available"
                    )}
                </BackButtonHeader>
                {
                    fetchedImages &&
                    <Carousel
                        showThumbs={true}
                        infiniteLoop={true}
                        showIndicators={true}
                        transitionTime={200}
                        swipeable={true}
                        emulateTouch={true}
                        dynamicHeight={true}
                        showStatus={false}

                    >
                        {fetchedImages.map((image, index) => (
                            <ImageContainer key={index}>
                                <img src={image.original} alt={image.caption} />
                                {image.caption && <p className="legend">{image.caption}</p>}
                            </ImageContainer>
                        ))}
                    </Carousel>
                }
                {innlegg && (
                    <Description>
                        {(language === "NO" ? innlegg.noBeskrivelse : innlegg.enBeskrivelse) ||
                            innlegg.noBeskrivelse ||
                            innlegg.enBeskrivelse ||
                            "No Description Available"}
                    </Description>
                )}
            </AnimatedSection>
        </Wrapper>
    );
}

export default Innlegg;