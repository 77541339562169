import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useViewContext } from '../Context/ViewContext.jsx';

const LanguageSwitcher = () => {
    const { language, setLanguage } = useViewContext();

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
    }

    return (
        <ButtonGroup size="small">
            <Button
                onClick={() => handleLanguageChange('EN')}

                variant={language === 'EN' ? "contained" : "outlined"}
            >
                EN
            </Button>
            <Button
                onClick={() => handleLanguageChange('NO')}

                variant={language === 'NO' ? "contained" : "outlined"}
            >
                NO
            </Button>
        </ButtonGroup>
    );
}

export default LanguageSwitcher;
