import styled from 'styled-components';
import CardComponent from '../Components/Card';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getCategories } from '../services/sanity.js';
import CircularProgress from '@mui/material/CircularProgress';
import { useViewContext } from '../Context/ViewContext.jsx';
import BackButtonHeader from '../Components/Elements/BackButtonHeader';
import AnimatedSection from '../Components/Elements/AnimatedSection';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px 20px 60px 20px;

    @media (max-width: 840px) {
        padding: 20px 20px 60px 20px;
    }
`

const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: 840px) {
        justify-content: center;
    }
`
const Kunst = () => {
    const { language } = useViewContext();
    const [kategorier, setKategorier] = useState(null);
    const [cardWidth, setCardWidth] = useState(window.innerWidth < 700 ? window.innerWidth -60 : 470);
    const cardHeight = Math.round((9 / 16) * cardWidth);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        async function fetchCategories() {
            const data = await getCategories();
            setKategorier(data[0]);
        }
        fetchCategories();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 700) {
                setCardWidth(Math.round(window.innerWidth - 60));
            } else {
                setCardWidth(470);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isOnKunstRoot = location.pathname === '/kunst';

    if (!kategorier) return <Wrapper> <CircularProgress color="inherit" /> </Wrapper>;

    return (
        <Wrapper>
            {(isOnKunstRoot) && (
                <AnimatedSection>
                    <BackButtonHeader> {language === "NO" ? "Innlegg" : "Posts"} </BackButtonHeader>
                    <CardContainer>
                        <CardComponent
                            cardWidth={cardWidth}
                            cardHeight={cardHeight}
                            title={language === "NO" ? "Verk" : "Artwork"}
                            text={language === "NO" ? kategorier.verk_noBeskrivelse : kategorier.verk_enBeskrivelse}
                            imageUrl={kategorier.verk_bilde.asset._ref}
                            onClick={() => { navigate("verk") }}
                        />
                        <CardComponent
                            cardWidth={cardWidth}
                            cardHeight={cardHeight}
                            title={language === "NO" ? "Utsmykking" : "Public work"}
                            text={language === "NO" ? kategorier.utsmykking_noBeskrivelse : kategorier.utsmykking_enBeskrivelse}
                            imageUrl={kategorier.utsmykking_bilde.asset._ref}
                            onClick={() => { navigate("utsmykking") }}
                        />
                        <CardComponent
                            cardWidth={cardWidth}
                            cardHeight={cardHeight}
                            title={language === "NO" ? "Utstilling" : "Exhibition"}
                            text={language === "NO" ? kategorier.utstilling_noBeskrivelse : kategorier.utstilling_enBeskrivelse}
                            imageUrl={kategorier.utstilling_bilde.asset._ref}
                            onClick={() => { navigate("utstilling") }}
                        />
                        <CardComponent
                            cardWidth={cardWidth}
                            cardHeight={cardHeight}
                            title={language === "NO" ? "Maritimt" : "Maritime"}
                            text={language === "NO" ? kategorier.maritimt_noBeskrivelse : kategorier.maritimt_enBeskrivelse}
                            imageUrl={kategorier.maritimt_bilde.asset._ref}
                            onClick={() => { navigate("maritimt") }}
                        />
                    </CardContainer>
                </AnimatedSection>
            )}
            <Outlet />
        </Wrapper>
    );
}

export default Kunst;
