import React, { createContext, useContext, useEffect, useState } from 'react';

const ViewContext = createContext();

export const ViewProvider = ({ children }) => {
    const [language, setLanguage] = useState(() => {
        const savedLanguage = localStorage.getItem('language');
        return savedLanguage || 'NO';
    });

    // Save to localStorage whenever language changes
    useEffect(() => {
        localStorage.setItem('language', language);
    }, [language]);

    const value = {
        language,
        setLanguage,
    };

    return (
        <ViewContext.Provider value={value}>
            {children}
        </ViewContext.Provider>
    );
};

export const useViewContext = () => {
    const context = useContext(ViewContext);
    if (!context) {
        throw new Error('useViewContext must be used within a ViewProvider');
    }
    return context;
};
