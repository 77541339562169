import { Typography, Link } from "@mui/material";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import styled from "styled-components";
import { useViewContext } from "../Context/ViewContext.jsx";
import footerImage from "../Assets/footer.jpeg";

const FooterWrapper = styled.div`
    padding: 120px 20px 120px 20px;
    position: relative;
    color: white;
    background-color: #111b29;
    overflow: hidden;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${footerImage});
        background-size: cover;
        background-position: center;
        background-attachment: scroll;
        filter: grayscale(20%);
        opacity: 0.4;
        z-index: 0;
    }

    @media (max-width: 700px) {
            padding: 90px 20px 90px 20px;
    }

`;

const ContentWrapper = styled.div`
    position: relative;
    z-index: 1;
`;

const StyledLink = styled(Link)`
    opacity: 1;
    font-weight: 500!important;
    text-shadow: 0px 0px 10px black;
    margin: 5px 0;
    display: flex;
    align-items: center;
    flex-direction: ${props => props.$align === 'right' ? 'row-reverse' : 'row'};
    flex-wrap: nowrap;
    text-decoration: none!important;
    color: white!important;
    gap: 16px;
    transition: 0.1s;

    &:hover {
        color: #b9e0ff!important;
        transform: translateY(1px);
    }

    @media (max-width: 600px) {
        flex-direction: row;
    }
`;

const FlexContainer = styled.div`
    margin: 27px 0;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: ${props => props.$align === 'right' ? 'row-reverse' : 'row'};

    @media (max-width: 600px) {
        flex-direction: row;
    }
`;

const Grid = styled.div`
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: ${props => props.$align === 'right' ? 'flex-end' : 'flex-start'};

    @media (max-width: 600px) {
        align-items: flex-start;
    }
`;

const ContactContainer = styled.div`
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 5%;

    @media (max-width: 600px) {
        flex-direction: column-reverse;
        align-items: stretch;
        gap: 30px;
    }
`;

const Footer = ({ innhold }) => {
    const { language } = useViewContext();

    return (
        <FooterWrapper>
            <ContentWrapper>
                <ContactContainer>
                    <Grid $align="right">
                        <Typography variant='h6' sx={{ fontWeight: 'bold', marginBottom: 2 }}>{language === "NO" ? "Sosial" : "Social"}</Typography>
                        <StyledLink $align="right" href={`https://instagram.com/${innhold.instagram1}`} target="_blank" rel="noopener noreferrer">
                            <InstagramIcon fontSize="small" />
                            <Typography variant="body1"> @{innhold.instagram1} </Typography>
                        </StyledLink>
                        <br />
                        <StyledLink $align="right" href={`https://instagram.com/${innhold.instagram2}`} target="_blank" rel="noopener noreferrer">
                            <InstagramIcon fontSize="small" />
                            <Typography variant="body1">@{innhold.instagram2} </Typography>
                        </StyledLink>
                    </Grid>
                    <Grid $align="left">
                        <Typography variant='h6' sx={{ fontWeight: 'bold', marginBottom: 2 }}>{language === "NO" ? "Kontakt" : "Contact"}</Typography>
                        <StyledLink $align="left" href={`mailto:${innhold.email}`}>
                            <EmailOutlinedIcon fontSize="small" />
                            <Typography variant="body1">{innhold.email} </Typography>
                        </StyledLink>
                        <FlexContainer $align="left">
                            <PlaceOutlinedIcon />
                            <Typography variant="body1">{innhold.adress}</Typography>
                        </FlexContainer>
                    </Grid>
                </ContactContainer>
            </ContentWrapper>
        </FooterWrapper>
    );
};

export default Footer;
