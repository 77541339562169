import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

const Header = styled.div`
    display: flex;
    justify-content: left;
    align-items:center ;
    gap: 10px;
    margin: 20px 0;
    width: 100%;

    @media (max-width: 740px) {
        margin:  0;
    }
`

const BackButton = styled.div`
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    align-self: flex-start;

    &:hover {
        cursor: pointer;
    }

`

const BackButtonHeader = ({ children }) => {
    const navigate = useNavigate();
    return (
        <>
            <BackButton onClick={() => navigate(-1)}>
                <Header>
                    <ArrowLeftIcon sx={{ fontSize: 30, color: 'white' }} />
                    <h2>{children}</h2>
                </Header>
            </BackButton>
        </>
    )
}

export default BackButtonHeader;