import styled from "styled-components";
import { useState, useEffect } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import IconButton from '@mui/material/IconButton';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LanguageSwitcher from "./LanguageSwitcher";
import { useViewContext } from '../Context/ViewContext.jsx';

const LanguageSwitcherContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
`;

const CustomSwipeableDrawer = styled(SwipeableDrawer)`
z-index: 3000;
  .MuiDrawer-paper {
    width: 250px; 
  }
`;

const Header = styled.div`
    padding: 20px 0;
    display: flex;
    justify-content: center;
    position: fixed; // Changed from sticky to fixed
    top: 0;
    left: 0;
    width: 100%; // Ensure the header spans the full width
    z-index: 100;
    padding-right: 20px;
    h1 {
        font-size: 20px;
    }
`;

const Wrapper = styled.div`
    max-width: 1000px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
`;

const HeaderFont = styled.h1`
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    color: white;
    font-family: 'Cormorant SC', serif;
`;

const DrawerHeader = styled.div`
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
`;

const HeaderText = styled.button`
    border: none;
    background-color: transparent;
    &:hover {
        cursor: pointer;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;

const ActiveListItemButton = styled(ListItemButton)`
  && {
    border-right: 4px solid #90CAF9;
  }
`;

function useScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
}

const Navigation = () => {
    const { language } = useViewContext();
    const location = useLocation();
    const navigate = useNavigate();
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    useScrollToTop();

    const navigationTexts = {
        NO: {
            Hjem: 'Hjem',
            Kunst: 'Innlegg',
            Cv: 'Cv',
            Kontakt: 'Kontakt'
        },
        EN: {
            Hjem: 'Home',
            Kunst: 'Posts',
            Cv: 'Resume',
            Kontakt: 'Contact'
        }
    };

    const navigationMapping = [
        { text: navigationTexts[language].Hjem, path: '/' },
        { text: navigationTexts[language].Kunst, path: '/kunst' },
        { text: navigationTexts[language].Cv, path: '/cv' },
        { text: navigationTexts[language].Kontakt, path: '/kontakt' }
    ];

    return (
        <>
            <Header>
                <Wrapper>
                    <ButtonContainer>
                        <IconButton aria-label="Open menu" onClick={() => setDrawerOpen(!isDrawerOpen)}><MenuRoundedIcon sx={{ color: "white", fontSize: 20 }} /></IconButton>
                    </ButtonContainer>
                    <HeaderText onClick={() => { navigate("/") }}><HeaderFont>John Audun Hauge</HeaderFont></HeaderText>
                </Wrapper>
            </Header>

            <CustomSwipeableDrawer
                anchor="left"
                open={isDrawerOpen}
                onClose={() => setDrawerOpen(false)}
                onOpen={() => setDrawerOpen(true)}
            >
                <DrawerHeader>
                    <HeaderFont>John Audun Hauge</HeaderFont>
                </DrawerHeader>
                <div>
                    <List sx={{ padding: 0 }}>
                        {navigationMapping.map(({ text, path }) => {
                            let isActive = false;
                            if (path === '/' && location.pathname === '/') {
                                isActive = true;
                            } else if (path !== '/' && location.pathname.startsWith(path)) {
                                isActive = true;
                            }
                            const ListItemComponent = isActive ? ActiveListItemButton : ListItemButton;

                            return (
                                <ListItem disablePadding key={text}>
                                    <ListItemComponent component="button" onClick={() => {
                                        navigate(path);
                                        setDrawerOpen(false);
                                    }}>
                                        <ListItemText primary={text} />
                                    </ListItemComponent>
                                </ListItem>
                            );
                        })}
                    </List>

                    <Divider />
                </div>
                <LanguageSwitcherContainer>
                    <LanguageSwitcher />
                </LanguageSwitcherContainer>
            </CustomSwipeableDrawer>
        </>
    );
}

export default Navigation;
