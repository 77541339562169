import { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useViewContext } from '../Context/ViewContext.jsx';

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const IntroContent = styled(motion.div)`
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  background-color: #111b29ed;

  h1 {
    margin: 0;
    color: white;
    font-family: 'Cormorant SC', serif;
    margin-top: 40px;
  }

  p {
    padding: 20px 0;
  }

  @media (max-width: 700px) {
    p {
      
      margin-top: -10px;
    }
  }
`;

const MaxWidthContainer = styled.div`
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`;

const Arrow = styled(motion.button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  background-color: transparent;
  border: none;
  transition: all 0.1s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

const scrollDown = () => {
  window.scrollTo({
    top: window.innerHeight,
    behavior: 'smooth'
  });
}

const BottmPositionedText = ({ children }) => {
  const { language } = useViewContext();
  const videoWrapperRef = useRef(null);

  const adjustHeight = () => {
    if (videoWrapperRef.current) {
      const viewportHeight = window.innerHeight;
      videoWrapperRef.current.style.height = `${viewportHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();

    window.addEventListener('resize', adjustHeight);

    return () => {
      window.removeEventListener('resize', adjustHeight);
    };
  }, []);

  return (
    <Wrapper ref={videoWrapperRef}>
      <IntroContent
        initial={{ y: "100%" }}
        animate={{ y: ["100%", "0%"] }}
        transition={{
          duration: .45,
          delay: 1,
          ease: [0.42, 0, 0.58, 1] // Custom easing for a snappier effect
        }}
      >
        <MaxWidthContainer>
          <div>
            <h1>John Audun Hauge</h1>
            <p>
              {language === "EN" ?
                "John Audun Hauge is a sculptor based in Bergen, renowned for his public artworks across Norway. Working with various materials and forms, his sculptures are both timeless and inspiring. Explore his art and experience a world of creativity and craftsmanship"
                :
                "John Audun Hauge er en skulptør i Bergen, kjent for sine offentlige kunstverk rundt om i Norge. Han arbeider med en rekke materialer og uttrykksformer, og hans skulpturer er både tidløse og inspirerende. Utforsk hans kunst og opplev en verden av kreativitet og håndverk"
              }
            </p>
          </div>
          <div>
            <Arrow
              onClick={scrollDown}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <motion.div
                animate={{ y: [0, -10, 0] }}
                transition={{ repeat: Infinity, duration: 2 }}
              >
                <ArrowDropDownIcon sx={{ fontSize: 50 }} />
              </motion.div>
            </Arrow>
          </div>
        </MaxWidthContainer>
      </IntroContent>
    </Wrapper>
  );
};

export default BottmPositionedText;
