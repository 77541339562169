import styled from "styled-components";
import { Typography } from "@mui/material";

const Header = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 0 20px 0;

    h2 {
        font-size: 28px;
        font-weight: 500;
        color: white;
    }
`

const CenteredHeader = ({ children }) => {
    return (
        <>
            <Header>
                <Typography variant="h2">{children}</Typography>
            </Header>
        </>
    )
}

export default CenteredHeader;